import { CLOUDINARY_URL } from '@constants'

export default function cloudinaryUrl(type, url, params) {
  const trimUrl = url
    .replace(
      /https:\/\/cms-dev*\.selectmodel\.com\/([^\/]+\/)*wp-content\/uploads\//,
      ``
    )
    .replace(
      /https:\/\/cms-new*\.selectmodel\.com\/([^\/]+\/)*wp-content\/uploads\//,
      ``
    )
    .replace(
      /https:\/\/([\w]+\.)?cms\.selectmodel\.com\/([^\/]+\/)*wp-content\/uploads\//,
      ``
    )

  const setParams = params ? `/${params}` : ''
  return `${CLOUDINARY_URL}/${type}/upload${setParams}/wp-select-model/${trimUrl}`
}
